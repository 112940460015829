/**
 *  @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasth
 * @description Custom Button Field (Buuton Field)
 * @date 06/12/24
 * @version 0.0.1 - Initial Release
 */
import React, { useState } from "react";
import appColors from "../../Assets/AppTheme/appColors";
import { Box } from "@mui/material";

export default function GiftCardPayOptions({
  minimumValue,
  maximumValue,
  handelClick,
  selectedValue,
}) {
  const createOptions = (min, max) => {
    if (min) {
      if (min >= max) return [];
      const step = (max - min) / 4;
      return Array.from({ length: 5 }, (_, i) => Math.round(min + step * i));
    }
  };

  const options = createOptions(minimumValue, maximumValue);

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "auto", // Enable horizontal scrolling
        gap: "1rem",
        padding: "0.5rem 0",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {options?.map((option) => (
        <button
          key={option}
          style={{
            borderRadius: "8px",
            border: "1px solid #ccc",
            background:
              selectedValue == option
                ? appColors.lightblue
                : appColors.cardColor,
            color:
              selectedValue == option ? appColors.grayText : appColors.darkGray,
            cursor: "pointer",
            fontSize: ".8rem",
            fontWeight: "bold",
            padding: ".4rem",
          }}
          onClick={() => handelClick(option)} // Update selected value
        >
          ₹{option.toFixed(2)}
        </button>
      ))}
    </Box>
  );
}
