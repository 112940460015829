/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath R
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, DialogActions, DialogContent, IconButton } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import ImageLoader from "../../Utils/imageLoader";
import useProductImages from "../../Utils/productImages";
import StyledInputField from "../../TextFields/styledTextFile";
import { RedeemGiftCardAction } from "../../../Redux/actions/giftCardAction";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import { GetWalletDetails } from "../../../Redux/actions/paymentMethodAction";
import appTheme from "../../../Assets/AppTheme/appTheme";
import CancelIcon from "@mui/icons-material/Cancel";
import Star from "../../../Assets/newMobileIcon/GiftCards/Star.svg";
import BlurImage from "../../../Assets/newMobileIcon/GiftCards/blur.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GiftCardDetailsDialog({
  openDialog,
  setOpenDialog,
  giftCardDetails,
  setGiftCardDetails,
}) {
  const dispatch = useDispatch();

  const [password, setPassword] = React.useState("");
  const [images, setImgages] = React.useState([]);
  const [error, setError] = React.useState("");
  const [claimError, setClaimError] = React.useState("");
  // coustom hook to get gift card images
  const productImages = useProductImages(images, "giftcard");
  //For closing dialog box
  const handleClose = () => {
    setOpenDialog(false);
    setPassword("");
  };

  console.log("giftCardDetails");
  console.log(giftCardDetails);
  //useEffect to set images
  React.useEffect(() => {
    if (giftCardDetails?.giftCardDetails?.greeting?.selectedImage) {
      setImgages([giftCardDetails?.giftCardDetails?.greeting?.selectedImage]);
    }
  }, [giftCardDetails]);

  //function to handel password changes
  const handelEnterPassword = (e) => {
    setPassword(e.target.value);
  };

  //Function to handel password change and api hit
  React.useEffect(() => {
    if (password?.length === 6) {
      const reqObject = {
        giftCardId: giftCardDetails?.giftCardDetails?.giftCardId,
        giftCardPassword: password, 
      };
      handelRedeemAction(reqObject);
    }
  }, [password]);
  //function to handel api call for redeem
  const handelRedeemAction = (reqObject) => {
    dispatch(RedeemGiftCardAction(reqObject)).then((data) => {
      if (
        data?.data?.isPasswordCorrect === false ||
        data?.data?.isPasswordCorrect === true
      ) {
        setGiftCardDetails(data?.data);
        if (data?.data?.isPasswordCorrect === false) {
          setError(data?.msg);
        } else {
          setError("");
        }
      } else if (data?.error) {
        setError(data?.msg);
        setClaimError(data?.msg);
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: data?.msg,
          })
        );
      } else if (!data?.error) {
        setError("");
        setGiftCardDetails({});
        setOpenDialog(false);
        setPassword("");
        dispatch(GetWalletDetails());
        dispatch(
          ToastMsgState({
            error: false,
            containerFlag: true,
            msg: data?.msg,
          })
        );
      } else {
        setGiftCardDetails({});
      }
    });
  };

  //function to handel add to cart
  const handelAddToList = () => {
    const reqObject = {
      giftCardId: giftCardDetails?.giftCardDetails?.giftCardId,
      giftCardPassword: password,
      isProceedTo: "ADDTOLIST",
    };
    handelRedeemAction(reqObject);
  };

  //function to handel redeem now button
  const handelRedeemNow = () => {
    const reqObject = {
      giftCardId: giftCardDetails?.giftCardDetails?.giftCardId,
      giftCardPassword: password,
      isProceedTo: "REDEEMNOW",
    };
    handelRedeemAction(reqObject);
  };

  //useEffect to set usestate as empty
  React.useEffect(() => {
    if (claimError !== "") {
      setTimeout(() => {
        setClaimError("");
      }, 5000);
    }
  }, [claimError]);
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ backgroundColor: "#038ec2cc" }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent
          sx={{
            background:
              "linear-gradient(360deg, rgba(3,142,194,.9) 100%, rgba(90,208,254,0) 93%)",
            position: "relative",
            paddingTop: "5rem",
          }}
        >
          {" "}
          {[
            { top: 125, right: -1, height: "1.5rem" },
            { top: 25, right: -3, height: "2rem" },
            { top: 225, right: 5, height: "1.3rem" },
            { top: 315, right: -3, height: "1.8rem" },
            { top: 95, left: -1, height: "1.3rem" },
            { top: 5, left: -3, height: "1.8rem" },
            { top: 195, left: 10, height: "1.3rem" },
            { top: 295, left: 0, height: "2rem" },
            { top: 395, left: -1, height: "2rem" },
            { top: 395, right: 2, height: "1.5rem" },
            { top: 0, left: 111, height: "1.8rem" },
            { top: 16, left: 220, height: "1rem" },
            { top: 0, left: 280, height: "1.8rem" },
            { top: 40, left: 60, height: "1.4rem" },
            { top: 56, left: 170, height: "1.8rem" },
            { top: 65, left: 270, height: "1rem" },
          ].map((props, index) => (
            <Box
              key={index}
              sx={{
                position: "absolute",
                top: props.top,
                left: props.left,
                right: props.right,
                zIndex: 10,
              }}
            >
              <img
                src={Star}
                alt="Star"
                style={{
                  transform: "rotate(30deg)",
                  height: props.height ?? "auto",
                  width: props.width ?? "auto",
                }}
              />
            </Box>
          ))}
          <Box
            sx={{
              background: appColors.themeBlue,
              borderRadius: "1rem",
              padding: "1rem",
              
            }}
          >
            <Box
              sx={{
                borderRadius: "1rem 1rem 0 0",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                // alignItems: "flex-end",
                // padding: "1rem",
                // position: "relative",
                backgroundColor: appColors.themeBlue,
              }}
            >
              <ImageLoader
                filename={
                  giftCardDetails?.giftCardDetails?.greeting?.selectedImage
                }
                styles={{
                  width: "100%",
                  height: "8rem",
                  objectFit: "fill",
                  borderRadius: "1rem",
                }}
              />
            </Box>

            <Box>
              <img
                alt=""
                src={BlurImage}
                style={{ width: "100%", height: ".7rem" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  marginTop: ".2rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: appTheme.fontSizexl,
                    color: "#7e7e7e",
                    fontWeight: "bold",
                  }}
                >
                  GIFT CARD
                </Typography>
                <Typography
                  sx={{
                    fontSize: appTheme.fontSizexl,
                    color: "#7e7e7e",
                  }}
                >
                  ₹ {giftCardDetails?.giftCardDetails?.payingAmount}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderBottom: "2px dashed gray",
                  width: "100%",
                  margin: "0rem 0",
                }}
              ></Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: appTheme.fontSizeMedium,
                    color: "#7e7e7e",
                    fontWeight: "bold",
                  }}
                >
                  Message :{" "}
                  <span
                    style={{
                      fontSize: appTheme.fontSizeMedium,
                      color: appColors.giftCardColor,
                      fontWeight: "600",
                    }}
                  >
                    {giftCardDetails?.giftCardDetails?.greeting?.ownWishes}
                  </span>
                </Typography>

                <Typography
                  sx={{
                    fontSize: appTheme.fontSizeMedium,
                    color: "#7e7e7e",
                    fontWeight: "bold",
                  }}
                >
                  From :{" "}
                  <span
                    style={{
                      fontSize: appTheme.fontSizeMedium,
                      color: appColors.giftCardColor,
                      fontWeight: "600",
                    }}
                  >
                    {giftCardDetails?.giftCardDetails?.creatorName}
                  </span>
                </Typography>
                {!giftCardDetails?.isPasswordCorrect && (
                  <Box>
                    <Typography
                      style={{
                        fontSize: appTheme.fontSizeMedium,
                        color: "#7e7e7e",
                        fontWeight: "600",
                      }}
                    >
                      Enter Gift Card Password
                    </Typography>
                    <StyledInputField
                      placeholder="Password"
                      text="password"
                      handelTextField={handelEnterPassword}
                      value={password}
                      error={error}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: appTheme.fontSizeMedium,
                    color: appColors.Failed,
                    fontWeight: "600",
                  }}
                >
                  {claimError}
                </Typography>
              </Box>
              {giftCardDetails?.isPasswordCorrect && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Button
                    sx={{
                      color: appColors.white,
                      background: appColors.giftCardColor,
                      "&:hover": {
                        background: appColors.giftCardColor,
                      },
                    }}
                    varient="contained"
                    size="small"
                    onClick={handelAddToList}
                  >
                    Add to List
                  </Button>{" "}
                  <Button
                    sx={{
                      color: appColors.white,
                      background: appColors.giftCardColor,
                      "&:hover": {
                        background: appColors.giftCardColor,
                      },
                    }}
                    varient="contained"
                    size="small"
                    onClick={handelRedeemNow}
                  >
                    Redeem Now
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            overflow: "hidden",

            background:
              "linear-gradient(360deg, rgba(3,142,194,.9) 100%, rgba(90,208,254,0) 93%)",
          }}
        >
          {[
            { bottom: 16, left: 50 },
            { bottom: 30, left: 190, height: "1rem" },
            { bottom: 10, left: 380 },
          ].map((props, index) => (
            <Box
              key={index}
              sx={{
                position: "absolute",
                bottom: props.bottom,
                left: props.left,
                right: props.right,

                zIndex: 10,
              }}
            >
              <img
                src={Star}
                alt="Star"
                style={{
                  transform: "rotate(30deg)",
                  height: props.height ?? "auto",
                  width: props.width ?? "auto",
                }}
              />
            </Box>
          ))}
          <IconButton
            aria-label="fingerprint"
            color="success"
            onClick={handleClose}
          >
            <CancelIcon sx={{ color: appColors.white }} />
          </IconButton>
          &nbsp;
        </DialogActions>
      </Dialog>
    </div>
  );
}
