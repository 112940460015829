/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */ import * as React from "react";
import { Box, Grid, Backdrop, Typography } from "@mui/material";
import { styles } from "../Newprofile/profileStyles";
import ReferandEarnTopBar from "./ReferandEarnTopBar";
import { influencerType } from "../../Assets/Constants/AppMetaData";
import { useDispatch, useSelector } from "react-redux";
import ReferandEarnLink from "./ReferandEarnLink";
import { ReferlinkStyles } from "./referalStyles";
import WaterDropsDetails from "./WaterDrops";
import waterDrop from "../../Assets/newMobileIcon/waterDrops 1.svg";
import WaterWalletTransactionCardNew from "./TransactionCard";
import SwipeableViews from "react-swipeable-views";
import "../../App.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import WaterDropFilterDialog from "../../Components/DialogBoxes/FilterDialogBox/waterDropFilterOption";
import moment from "moment";
import {
  GetWaterDropsUser,
  TransactionDetails,
} from "../../Redux/actions/referAndEarnAction";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
export default function ReferandEarnMainPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const pageValue = new URLSearchParams(search).get("pageValue");
  const profileDetails = useSelector((state) => state.userProfile);
  const referalHistory = useSelector((state) => state.referAndEarnDetails);
  const [processTabs, setProcessTabs] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [requestParams, setRequestParams] = React.useState({
    from: moment().subtract(180, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    sort: "DESC",
    page_number: 0,
    transaction_status: null,
  });
  const [referLoading, setReferLoading] = React.useState(true);
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
  React.useEffect(() => {
    dispatch(GetWaterDropsUser());
  }, []);

  const filterOption = [
    { All: null },
    {
      "Referrer Rewards": 1001,
    },
    { "Recipient Rewards": 1002 },
    {
      "Admin Rewards": 1011,
    },
    { "Cash Back Rewards": 0 },
    { "Marketing Rewards": 1111 },
    { "Signup Rewards": 100 },
    { Redeemed: 1010 },
    { "Refund for Order Cancel": 1005 },
    { "Promo code discount": 1003 },
    { "Consumer Paid Package Drinking Water": 1004 },
  ];
  const handleSwipeChange = (index) => {
    setProcessTabs(index);
    if (index == 2) {
      history.push("/dashboard/referandearn?pageValue=2");
    } else if (index == 0) {
      history.push("/dashboard/referandearn?pageValue=0");
    }
  };

  // //useEffect to set water drop tab
  // React.useEffect(() => {
  //   if (processTabs !== 1) {
  //     removeQueryParamAndNavigate();
  //   }
  // }, [processTabs]);

  //useEffect to set water drop tab
  React.useEffect(() => {
    if (pageValue !== null && pageValue == 1) {
      setProcessTabs(1);
    } else if (pageValue !== null && pageValue == 2) {
      setProcessTabs(2);
    } else {
      setProcessTabs(0);
    }
  }, [pageValue]);

  //function for loading icon and clering selected products after orders page
  React.useEffect(() => {
    setTimeout(() => {
      setReferLoading(false);
    }, 1500);
  }, []);

  //function to handel tab change
  const handleProcessTabshange = (newProcessValue) => {
    setProcessTabs(newProcessValue);
    if (newProcessValue == 2) {
      history.push("/dashboard/referandearn?pageValue=2");
    } else if (newProcessValue == 0) {
      history.push("/dashboard/referandearn?pageValue=0");
    }
  };

  //function to call api request
  React.useEffect(() => {
    if (processTabs === 2) {
      dispatch(TransactionDetails(requestParams));
    } else {
    }
  }, [processTabs, requestParams]);

  //function to change page Number
  React.useEffect(() => {
    setRequestParams((prev) => ({
      ...prev,
      page_number: pageNumber - 1,
    }));
  }, [pageNumber]);
  return (
    <>
      <Box sx={styles.profileMain} className="page">
        <Grid container direction="column" sx={{}}>
          <Grid item xs={12}>
            {influencerType.USER_TYPE_INFLUENCER ===
            parseInt(profileDetails.consumer.user_type) ? (
              <Grid item xs={12}>
                <Typography sx={ReferlinkStyles.referText2}>
                  Welcome BookWater Ambassador{" "}
                  <span>
                    {" "}
                    <img
                      src={waterDrop}
                      style={{ height: "1.5rem", width: "1.5rem" }}
                      alt="Bookwater"
                    />
                  </span>
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <ReferandEarnTopBar
              processTabs={processTabs}
              setProcessTabs={setProcessTabs}
              handleProcessTab={handleProcessTabshange}
            />
          </Grid>
          <Grid item xs={12} sx={styles.profileDetailsGrid}>
            <SwipeableViews
              index={processTabs}
              onChangeIndex={handleSwipeChange}
            >
              <Box>{processTabs === 0 && <ReferandEarnLink />}</Box>
              <Box> {processTabs === 1 && <WaterDropsDetails />}</Box>
              <Box>
                {processTabs === 2 && (
                  <WaterWalletTransactionCardNew
                    referalHistory={referalHistory}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    filterDialogOpen={filterDialogOpen}
                    setFilterDialogOpen={setFilterDialogOpen}
                  />
                )}
              </Box>
            </SwipeableViews>
          </Grid>
        </Grid>
      </Box>
      <WaterDropFilterDialog
        filterDialogOpen={filterDialogOpen}
        setfilterDialogOpen={setFilterDialogOpen}
        filterOption={filterOption}
        setRequestParams={setRequestParams}
        requestParams={requestParams}
        setPageNumber={setPageNumber}
      />
      <ToastMsgCotainer />
    </>
  );
}
