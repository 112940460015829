/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */

import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";
export const styles = {
  backButton: {
    backgroundColor: appColors.white,
    marginBottom: "1rem",
    marginTop: { xs: "4rem" },
    height: "2.5rem",
    padding: 0,
  },
  BackText: {
    color: appColors.darkBlue,
    fontWeight: "bold",
    alignText: "end",
    fontSize: appTheme.fontSizeLarge,
    marginLeft: ".5rem",
    marginTop: ".5rem",
  },
  containerStyle: {
    marginTop: { xs: "4.5rem" },
    paddingRight: "5px",
    paddingBottom: "20px",
    paddingLeft: "5px",
    marginBottom: { sm: "1rem", xs: "8rem" },
  },
  columnFlexStart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "2px",
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px",
  },
  typoGraphyCard: {
    fontWeight: "600",
    fontSize: appTheme.fontSizeMedium,
  },
  formHrLine: {
    border: "none",
    borderTop: "1.5px solid black",
    margin: "10px 0px",
  },
  radioFormStyle: {
    border: "1px solid black",
    borderRadius: "8px",
    width: "100%",
    "& .MuiFormControlLabel-label": {
      fontSize: appTheme.fontSizeSmall,
    },
    "&: hover": {
      backgroundColor: "#152058",
      color: "white",
      "& .MuiRadio-colorPrimary": {
        color: "white",
      },
    },
  },
  proceedBtn: {
    marginTop: "20px",
    marginLeft: "10px",
  },
  proceedBtn_2: {},
  canQtyStyle: {
    backgroundColor: appColors.lightblue,
    height: "30px",
    width: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "600",
    fontSize: appTheme.fontSizeSmall,
  },
  paymentCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: {lg:"80%",md:"80%",sm:"100%",xs:"100%"},
    textAlign: "center",
    gap: "3px",
  },
  paymentCardMedia: {
    height: "23px",
    width: "20px",
    padding: "5px",
    paddingLeft: "10px",
  },
  paytmentTypo: {
    fontSize: appTheme.fontSizeSmall,
    fontWeight: "bold",
    marginTop: "5px",
    color: appColors.darkBlue,
  },
  razorPayCardStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "80%",
    width: "100%",
  },
  upiCardStyle: {
    height: "50px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editTextStyle: {
    fontSize: appTheme.fontSizeMedium,
    color: "grey",
    fontWeight: "500",
  },
  flexenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  starBowlAlignParent: {
    display: "flex",
    flexDirection: "column",
    color: appColors.darkGray,
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  starBowlSize: {
    width: "100px",
    height: "100px",
    marginTop: "1rem",
  },
  successTypo: {
    fontSize: appTheme.fontSizeLarge,
    fontWeight: "600",
    color: appColors.darkGray,
    marginTop: "1rem",
    textAlign: "center",
  },
  hrStyling: {
    border: "none",
    borderTop: "1.5px solid black",
    width: "10%",
  },
  thankTypo: {
    fontSize: appTheme.fontSizeMedium,
    color: appColors.darkGray,
    fontWeight: "600",
  },
};
export const newBookingstyles = {
  toastContainer: {
    position: "fixed",
    alignItems: "center",
    zIndex: 6,
    right: 0,
    top: "4rem",
    backgroundColor: appColors.Failed,
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  tostContainerBox: {
    margin: "0 0.3rem 0 0.3rem",
    display: "flex",
    alignItems: "center",
  },
  cardStyles: {
    backgroundColor: appColors.cardColor,
    height: { xs: "4.2rem", sm: "5rem" },
    width: "100%",
    boxShadow: "3",
    display: "flex",
    borderRadius: ".7rem",
  },
  cardStylesAddress: {
    backgroundColor: appColors.cardColor,
    width: "100%",
    boxShadow: "3",
    display: "flex",
    borderRadius: ".7rem",
  },
  cardStylesCoupon: {
    backgroundColor: appColors.cardColor,
    // height: "7rem",
    width: "100%",
    boxShadow: "4",
    display: "flex",
    borderRadius: ".7rem",
  },
  cardStyles1: {
    backgroundColor: appColors.cardColor,
    width: "100%",
    boxShadow: "4",
    display: "flex",
  },
  cardStyles3: {
    backgroundColor: appColors.cardColor,
    width: "100%",
    boxShadow: "4",
    display: "flex",
    flexDirection: "column",
    borderRadius: ".7rem",
  },
  cardHeaderStyle: {
    fontWeight: "bold",
    color: appColors.headerTextColor,
    fontSize: appTheme.fontSizeMedium,
    marginBottom: ".5rem",
  },
  MainBox: {
    display: "flex",
    flexDirection: "column",
    padding: ".6rem 1rem",
    width: "80%",
  },
  BoxHeader: {
    fontSize: appTheme.fontSizeMedium,
    color: appColors.darkGray,
    fontWeight: "500",
  },
  BoxHeader1: {
    fontSize: appTheme.fontSizeMedium,
    color: appColors.headerTextColor,
  },
  addressText: {
    color: appColors.Textgray,
    color: appColors.darkGray,
    fontSize: appTheme.fontSizeSmall,
    width: "100%" /* Set the width to 80% */,
    whiteSpace: "nowrap" /* Prevent wrapping */,
    overflow: "hidden" /* Hide overflowing text */,
    textOverflow: "ellipsis" /* Display ellipsis when text overflows */,
  },

  editButton: {
    height: "1.4rem",
    width: "1.4rem",
    color: appColors.textColorNew,
  },
  wavePattern: { width: "100%", position: "absolute", bottom: 0 },
  wavePattern1: { width: "100%", position: "absolute", bottom: 0 },
  orderBox: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    width: "100%",
  },
  orderTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  orderButtonStyle: {
    backgroundColor: appColors.textColorNew,
    height: "30px",
    width: "40px",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "0.3rem 0 0 .3rem",
    color: "#fff",
  },
  orderButtonStylePlus: {
    backgroundColor: appColors.textColorNew,
    height: "30px",
    width: "40px",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "0 0.3rem .3rem 0",
    color: "#fff",
  },
  addAddressChildBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
  Transactonspinner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  promoBoxStyles: {
    width: "70%",
    height: "30px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    // backgroundColor: appColors.textColorNew,
    cursor: "pointer",
    "&: hover": {
      "& .MuiTypography-root": {
        color:appColors.textColorNew,
      },
    },
  },
};
