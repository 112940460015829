/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description coupon dialog box
 * @date
 * @version
 */

import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import appColors from "../../../../Assets/AppTheme/appColors";
import Lottie from "lottie-react";
import animationData from "../../../../Assets/Animations/doneFile.json";
import { Box, IconButton } from "@mui/material";
import animate from "lottie-web";
export default function CouponAppliedDialog(props) {
  const { onClose, open, promoCode } = props;

  const handleClose = () => {
    onClose(false);
    animate.destroy();
  };

  // useEffect to close Dialog box
  React.useEffect(() => {
    let timeoutId;
    if (open) {
      timeoutId = setTimeout(() => {
        handleClose();
      }, 10000);
    }
    // Clear timeout when the dialog box is closed
    return () => {
      clearTimeout(timeoutId);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: appColors.secondaryBlue,
          width: "14rem",
        }}
      >
        {promoCode ? (
          <Box
            sx={{
              height: "6rem",
              width: "6rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie animationData={animationData} loop={true} autoplay={true} />
          </Box>
        ) : null}
        <List sx={{ pt: 0 }}>
          <ListItem sx={{ justifyContent: "center" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1rem",
                fontWeight: "bold",
                color: appColors.grayText,
              }}
            >
              <span style={{ color: appColors.giftCardColor }}>
                {promoCode}
              </span>{" "}
            </Typography>
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: ".9rem",
                fontWeight: "bold",
                color: appColors.grayText,
              }}
            >
              Coupon applied!
            </Typography>
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            <IconButton
              aria-label="fingerprint"
              onClick={handleClose}
              sx={{ padding: "0 0 .3rem 0",color:appColors.giftCardColor,fontSize:".85rem" }}
            >
              Close
            </IconButton>
          </ListItem>
        </List>
      </Box>
    </Dialog>
  );
}
