/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import CustomConsole from "../../coustomConsole";
import { GetWalletDetails } from "./paymentMethodAction";
import { GetWaterDropsUser } from "./referAndEarnAction";

//Get all the Available Products Based on Address Orders
export const BookSkuProductAction = (reqObj) => {
  // CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/booking/products/booknow`, reqObj);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SKU_ORDER_PLACING_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
        dispatch(GetWalletDetails());
        dispatch(GetWaterDropsUser());
      } else {
        dispatch({
          type: actionType.SKU_ORDER_PLACING_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.SKU_ORDER_PLACING_ACTION,
        payload: { data: error.response.data, statusFlag: true },
      });
      dispatch({
        type: actionType.TOAST_MSG_CONTAINER,
        payload: {
          data: {
            error: true,
            containerFlag: true,
            msg: error.response.data.msg,
          },
        },
      });
    }
  };
};

//function for reset order state
export const ResetOrderState = () => {
  return {
    type: actionType.RESET_ORDER_STATE,
  };
};

//Get all the reorder Products details on previous Orders
export const ReOrderDetailsAction = (reqObj) => {
  // CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    dispatch({ type: actionType.RE_ORDER_DETAILS_FLAG });
    try {
      let response = await api.post(`/OM/order/reorder`, reqObj);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REORDER_DETAILS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        dispatch({
          type: actionType.REORDER_DETAILS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.REORDER_DETAILS_ACTION,
        payload: { data: error.response.data, statusFlag: true },
      });
      dispatch({
        type: actionType.TOAST_MSG_CONTAINER,
        payload: {
          data: {
            error: true,
            containerFlag: true,
            msg: error.response.data.msg,
          },
        },
      });
    }
  };
};

//Get all the reorder Products details on previous Orders
export const EditOrderActionSku = (reqObj) => {
  // CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    dispatch({ type: actionType.RE_ORDER_DETAILS_FLAG });
    try {
      let response = await api.put(`/OM/order/edit`, reqObj);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_ORDER_ACTION_SKU,
          payload: { data: response.data, statusFlag: true },
        });
        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: false,
              containerFlag: true,
              msg: response.data.msg,
            },
          },
        });
        return response;
      } else {
        dispatch({
          type: actionType.EDIT_ORDER_ACTION_SKU,
          payload: { data: response.data, statusFlag: true },
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: actionType.EDIT_ORDER_ACTION_SKU,
        payload: { data: error.response.data, statusFlag: true },
      });
      if (error?.response?.data?.deposit_details === undefined) {
        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: true,
              containerFlag: true,
              msg: error.response.data.msg,
            },
          },
        });
      }
      return error.response;
    }
  };
};

//function for getting new order id for paysharp payments
export const GetNewOrderId = (reqObj) => {
  // CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/upi/create/orderid`, reqObj);

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.NEW_ORDERID_PAYSHARP,
          payload: { data: response.data, statusFlag: true },
        });
        return response?.data
      } else {
        dispatch({
          type: actionType.NEW_ORDERID_PAYSHARP,
          payload: { data: response.data, statusFlag: true },
        });
        return response?.data
      }
    } catch (error) {
      dispatch({
        type: actionType.NEW_ORDERID_PAYSHARP,
        payload: { data: error.response.data, statusFlag: true },
      });
      return error?.response?.data

    }
  };
};

//function for making order as true
export const OrderErrorChange = () => ({
  type: actionType.CANCEL_BOOKING_ORDER_ERROR,
});
