/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import orderFiled from "../../Assets/newMobileIcon/Booking/Order Failed.svg";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Box, Button, Dialog, DialogContent, Slide, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpiFailedDialog(props) {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const pageTitle = pathname.split("/dashboard/");
  const pageName = new URLSearchParams(search).get("pageName");
  const handleClose = () => {
    props.setOpenDepositSucessDialog(false);
    if (pageName && pageName === "booking") {
      history.push("/dashboard/myorders");
    } else {
      if (window.innerWidth > 600) {
        history.push("/dashboard/home");
      } else {
        history.push("/mobile");
      }
    }
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <DialogContent sx={{ backgroundColor: appColors.secondaryCard }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="" src={orderFiled} />
          <Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            Payment Failed.
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: appColors.darkGray,
              fontWeight: "500",
              marginTop: ".9rem",
              textAlign: "center",
            }}
          >
            Your payment has failed. Please try again !.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              variant="contained"
              sx={{
                marginTop: "15px",
                height: "30px",
                backgroundColor: appColors.textColorNew,
                color: "white",
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
