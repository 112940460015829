/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import { Box, Button, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import AvailedGiftCardList from "../../Components/Cards/GiftCards/availedGiftCardsList";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";
import TuneIcon from "@mui/icons-material/Tune";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAvailedGiftCards,
  GetNewGiftCardOrderId,
} from "../../Redux/actions/giftCardAction";
import PageNavigation from "../../Components/CommonComponents/PageNavigationDetails";
import GiftCardFilterDialog from "../../Components/DialogBoxes/FilterDialogBox/GiftCardFilter";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import AvailedGiftCardDialog from "../../Components/DialogBoxes/GiftCardDialog/availedGiftdetailsDialog";
import ExtraDepositPayDialog from "../../Components/DialogBoxes/SkuDialogs/extraDepositPayDialog";
import MyWallet from "../../Components/PaymentComponent/mywallet/myWallet";
import { GetPaymentBlockDetails } from "../../Redux/actions/PaymentActionSku";
import { ResetComponentState } from "../../Redux/actions/appMetaDataAction";
import CustomConsole from "../../coustomConsole";
import { ToastMsgState } from "../../Redux/actions/toastMsg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import DepositSucess from "../../Components/DialogBoxes/SkuDialogs/depositSucessPopup";
import giftCard from "../../Assets/newMobileIcon/GiftCards/giftCardBanner.svg";
import appMessages from "../../Assets/AppMeta/appMessages";
import { GetNewOrderId } from "../../Redux/actions/skuOrderPlacingAction";

export default function AvailedGiftCards() {
  const history = useHistory();
  const [filterDailogOpen, setFilterDialogOpen] = useState(false);
  const [giftCardId, setGiftCardId] = useState("");
  const [openGiftCardView, setOpenGiftCardView] = useState(false);
  const [availedGiftCards, setAvailedGiftCards] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [fromDateValue, setFromDateValue] = useState(
    moment().subtract(180, "days").format("YYYY-MM-DD")
  );
  const [toDateValue, setToDateValue] = useState(
    moment().add(6, "days").format("YYYY-MM-DD")
  );
  const [sortValue, setSortValue] = useState("DESC");
  const [payMentStatus, setPayMentStatus] = useState(null);
  const [category, setCategory] = useState(null);
  const [finalOrderPrice, setFinalOrderPrice] = useState(0);
  const [selectedGreeting, setSelectedGreeting] = useState({});
  const walletDetails = useSelector((state) => state.paymentMethods);
  const [promoCode, setpromoCode] = useState("");
  const [openVbDialog, setOpenVbDialog] = useState(false);
  const [upiId, setUpiId] = useState("");
  const [razorPayFlag, setrazorPayFlag] = useState(false);
  const [upiIdVPAObj, setUpiIdVPAObj] = useState({});
  const [verifyUpiClicked, setVerifyUpiIdClicked] = useState(false);
  const [checkOutButtonClick, setCheckOutButtonClick] = useState(false);
  const [orderPaymentMethod, setOrderPaymentMethod] = useState("");
  const [referLoading, setReferLoading] = useState(true);
  const paymentTypeWallet = {
    RAZORPAY: 0,
    PAYSHARP: 1,
    POSTPAID: 2,
    WATER_WALLET: 3,
    CLEARALL: 4,
  };
  const [payment, setPayment] = useState(paymentTypeWallet.PAYSHARP);
  const [upiUserOption, setUpiUserOption] = useState("2");
  const [upiPayUserOption, setUpiPayUserOption] = useState("");
  const [payType, setPayType] = useState(100);
  const [refundPaymentFlag, setRefundPaymentFlag] = useState(false);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [isUpiIdValid, setIsUpiIdValid] = useState(false);
  const [opean, setOpean] = useState(false);
  const [myWalletInBooking, setMyWalletInBooking] = useState(false);
  const [payFor, setPayFor] = useState("");
  const [payOptionCheck, setPayOptionCheck] = useState(false);
  const [choosenPartial, setChoosenPartial] = useState(false);
  const [bookingpaymentFlag, setBookingPaymentFlag] = useState(false);
  const [upiPay, setUpiPay] = useState(false);
  const paymentBlockDetails = useSelector(
    (state) => state.paymentDetailsSku.paymentBlockData
  );
  const BlocketPayments = new Set(paymentBlockDetails);
  //state for storing address ID
  const [paymentType, setPaymentType] = useState("PAYSHARP");
  const [paymentSucess, setOpenPaymentSuccessDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [multiplePaymentFlag, setMultiplePayment] = useState(false);
  const [
    useWalletBalancePayFlagforDeposit,
    setUseWalletBalancePayFlagforDeposit,
  ] = useState(true);
  const [openExtraDepositDialog, setOpenExtraDepositDialog] = useState(false);
  const [paymentTriggerFlag, setpaymentTriggerFlag] = useState(false);
  const [finalPrice, setFinalPrice] = useState(0);
  const [buttonError, setButtonError] = useState(false);

  //function to set amount
  const [amountToPay, setAmountToPay] = useState(0);
  const [paymentFlag, setPaymentFlag] = useState(false);

  // //useEffect for setting up an payment menthod
  useEffect(() => {
    let validPayment = "";
    if (!BlocketPayments.has(200)) {
      validPayment = "RAZORPAY";
      setPayment(paymentTypeWallet.RAZORPAY);
    } else if (
      (!BlocketPayments.has(100) && !BlocketPayments.has(101)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(102)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(103))
    ) {
      validPayment = "PAYSHARP";
    } else if (!BlocketPayments.has(300)) {
      validPayment = "WALLET";
      setPayment(paymentTypeWallet.WATER_WALLET);
    }
    setPaymentType(validPayment);
  }, []);

  //useEffect for getting payment block details
  useEffect(() => {
    dispatch(GetPaymentBlockDetails());
    return () => {
      dispatch(ResetComponentState());
    };
  }, []);

  //useEffect for getting payment block details
  useEffect(() => {
    if (window.innerWidth > 600) {
      setUpiUserOption("1");
      setPayType(200);
    }
    dispatch(ResetComponentState());
  }, []);

  //function to hit update api after getting payment sucess responce from orderstatus api
  useEffect(() => {
    if (
      (walletDetails?.paymentData?.data?.payment_status === "SUCCESS" &&
        !refundPaymentFlag) ||
      (walletDetails?.paymentData?.data?.status === "SUCCESS" &&
        !refundPaymentFlag)
    ) {
      setRefundPaymentFlag(true);
    }
  }, [walletDetails.paymentData]);

  // //useEffect for setting up an payment menthod
  useEffect(() => {
    if (walletDetails?.walletAmount >= finalOrderPrice) {
      if (
        !BlocketPayments.has(101) &&
        !BlocketPayments.has(100) &&
        window.innerWidth <= 600
      ) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("2");
        setPayType(100);
      } else if (!BlocketPayments.has(102) && !BlocketPayments.has(100)) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("1");
        setPayType(200);
      } else if (!BlocketPayments.has(300)) {
        setPaymentType("WALLET");
        setPayment(paymentTypeWallet.WATER_WALLET);
      } else if (!BlocketPayments.has(200)) {
        setPayment(paymentTypeWallet.RAZORPAY);
        setPaymentType("RAZORPAY");
      } else if (!BlocketPayments.has(103) && !BlocketPayments.has(100)) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("3");
        setPayType(100);
      }
    }

    if (finalOrderPrice !== undefined) {
      setFinalPrice(parseFloat(finalOrderPrice));
    }
  }, [finalOrderPrice]);

  //Useeffect for getting waterwallet payment method
  useEffect(() => {
    if (walletDetails?.isWalletSucess) {
      setPaymentType("PAYSHARP");
      setOpenPaymentSuccessDialog(true);
      dispatch(ResetComponentState());
    }
  }, [walletDetails]);

  // useEffect to calculate final price for multipayment commented due to hold in release
  useEffect(() => {
    if (
      useWalletBalancePayFlagforDeposit &&
      walletDetails?.walletAmount >= 1 &&
      bookingpaymentFlag &&
      amountToPay > walletDetails?.walletAmount &&
      amountToPay - parseFloat(walletDetails?.walletAmount) >= 1
    ) {
      CustomConsole(amountToPay + " " + "amountToPay");
      CustomConsole(
        walletDetails?.walletAmount + " " + "walletDetails?.walletAmount"
      );
      CustomConsole(amountToPay - walletDetails?.walletAmount);
      setAmountToPay(
        parseFloat(
          (amountToPay - parseFloat(walletDetails?.walletAmount)).toFixed(2)
        )
      );
    } else if (!useWalletBalancePayFlagforDeposit && bookingpaymentFlag) {
      setAmountToPay(parseFloat(finalOrderPrice));
    }
  }, [useWalletBalancePayFlagforDeposit, bookingpaymentFlag]);

  //useEffect to trigger payment option
  useEffect(() => {
    if (paymentTriggerFlag) {
      if (upiUserOption === "3" && isUpiIdValid) {
        setPaymentFlag(true);
        setOpenExtraDepositDialog(false);
      } else if (upiUserOption !== "3") {
        setPaymentFlag(true);
        setOpenExtraDepositDialog(false);
      } else {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: `Please verify your upi id to proceed`,
          })
        );
      }
      setpaymentTriggerFlag(false);
    }
  }, [paymentTriggerFlag]);

  //function to handel payment close dialog
  const handelClosePaymentSucess = () => {
    setOpenPaymentSuccessDialog(false);
    setOpenGiftCardView(false);
    handleFilterSubmit();
    history.push("/dashboard/giftcards");
  };

  const dispatch = useDispatch();
  //function to handel buy gift card action
  const handelClickBuyGiftCards = () => {
    setFilterDialogOpen(true);
  };

  // Api Function to call api
  const fetchGiftCardDetails = (object) => {
    let reqObj;
    if (object) {
      reqObj = object;
    } else {
      reqObj = {
        status: payMentStatus,
        sort: sortValue,
        from: fromDateValue,
        to: toDateValue,
        pageNumber: pageNumber - 1,
        pageSize: 10,
        category: category,
      };
    }
    dispatch(GetAvailedGiftCards(reqObj)).then((data) => {
      if (!data?.error) {
        setAvailedGiftCards(data?.data);
      } else {
        setAvailedGiftCards([]);
      }
    });
  };
  //useeffect to get availed gift cards
  useEffect(() => {
    fetchGiftCardDetails();
  }, [pageNumber]);

  //function to handel filter submit
  const handleFilterSubmit = (reqObj) => {
    fetchGiftCardDetails(reqObj);
  };

  //function to handel view giftcardDetails
  const handelViewGiftCard = (id) => {
    setOpenGiftCardView(true);
    setGiftCardId(id);
  };

  //function to payment on giftcard
  const handelPayGiftCard = () => {
    if (paymentType === "PAYSHARP") {
      dispatch(GetNewGiftCardOrderId({ giftCardId: giftCardId })).then(
        (data) => {
          if (!data?.error) {
            setGiftCardId(data?.data?.new_order_id);
            setpaymentTriggerFlag(true);
          }
        }
      );
    } else {
      setpaymentTriggerFlag(true);
    }
  };

  //function to open payment dialog
  const handelOpenPaymentDialog = (amount) => {
    setAmountToPay(amount);
    setFinalOrderPrice(amount);
    setBookingPaymentFlag(true);
    setOpenGiftCardView(false);
  };
  return (
    <>
      <Grid container sx={{}}>
        <Grid item xs={12}>
          <div style={availedGiftCardStyles.mainComponent}>
            <Typography style={availedGiftCardStyles.myGiftCardTest}>
              My Gift Cards
            </Typography>
            <Button
              startIcon={<TuneIcon />}
              onClick={handelClickBuyGiftCards}
              sx={availedGiftCardStyles.filterButton}
            >
              Filter
            </Button>{" "}
          </div>
        </Grid>{" "}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <PageNavigation
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              pageList={availedGiftCards?.length < 10 ? true : false}
            />
          </Box>
          <Box sx={availedGiftCardStyles.giftCardList}>
            {availedGiftCards?.length > 0 ? (
              <AvailedGiftCardList
                availedGiftCards={availedGiftCards}
                handelViewGiftCard={handelViewGiftCard}
              />
            ) : (
              <Typography
                style={{
                  fontSize: appTheme.fontSizexl,
                  color: appColors.giftCardColor,
                  fontWeight: "bold",
                }}
              >
                Gift details not found!{" "}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <GiftCardFilterDialog
        filterDialogOpen={filterDailogOpen}
        setfilterDialogOpen={setFilterDialogOpen}
        handleFilterSubmit={handleFilterSubmit}
        payMentStatus={payMentStatus}
        setPayMentStatus={setPayMentStatus}
        sortValue={sortValue}
        toDateValue={toDateValue}
        setToDateValue={setToDateValue}
        setSortValue={setSortValue}
        fromDateValue={fromDateValue}
        setFromDateValue={setFromDateValue}
        setPageNumber={setPageNumber}
        category={category}
        setCategory={setCategory}
      />
      {openGiftCardView && (
        <AvailedGiftCardDialog
          openDialog={openGiftCardView}
          setOpenDialog={setOpenGiftCardView}
          giftCardId={giftCardId}
          handleFilterSubmit={handleFilterSubmit}
          handelOpenPaymentDialog={handelOpenPaymentDialog}
        />
      )}

      <MyWallet
        myWalletInBooking={false}
        setpromoCode={setpromoCode}
        openVbDialog={openVbDialog}
        setOpenVbDialog={setOpenVbDialog}
        setpaymentFlag={setPaymentFlag}
        promoCode={promoCode}
        upiId={upiId}
        setrazorPayFlag={setrazorPayFlag}
        setUpiId={setUpiId}
        upiIdVPAObj={upiIdVPAObj}
        setUpiIdVPAObj={setUpiIdVPAObj}
        setVerifyUpiIdClicked={setVerifyUpiIdClicked}
        verifyUpiClicked={verifyUpiClicked}
        payment={payment}
        setPayment={setPayment}
        upiUserOption={upiUserOption}
        setUpiUserOption={setUpiUserOption}
        setUpiPayUserOption={setUpiPayUserOption}
        amountEntered={amountToPay}
        payType={payType}
        paymentTypeWallet={paymentTypeWallet}
        verifyBtn={verifyBtn}
        setVerifyBtn={setVerifyBtn}
        setPayType={setPayType}
        isUpiIdValid={isUpiIdValid}
        setIsUpiIdValid={setIsUpiIdValid}
        paymentFlag={paymentFlag}
        opean={opean}
        setOpean={setOpean}
        setMyWalletInBooking={setMyWalletInBooking}
        setAmountToAdd={setAmountToPay}
        setPayFor={setPayFor}
        setPayOptionCheck={setPayOptionCheck}
        choosenPartial={choosenPartial}
        setChoosenPartial={setChoosenPartial}
        orderId={giftCardId}
      />
      {bookingpaymentFlag && (
        <ExtraDepositPayDialog
          orderPaymentMethod={orderPaymentMethod}
          open={bookingpaymentFlag}
          setsubcribePaymentFlag={setBookingPaymentFlag}
          setPaymentType={setPaymentType}
          openVbDialog={openVbDialog}
          setOpenVbDialog={setOpenVbDialog}
          myWalletInBooking={false}
          setpaymentFlag={setPaymentFlag}
          upiId={upiId}
          setrazorPayFlag={setrazorPayFlag}
          setPerWeekPrice={amountToPay}
          setUpiId={setUpiId}
          upiIdVPAObj={upiIdVPAObj}
          setUpiIdVPAObj={setUpiIdVPAObj}
          setVerifyUpiIdClicked={setVerifyUpiIdClicked}
          verifyUpiClicked={verifyUpiClicked}
          payment={payment}
          setPayment={setPayment}
          upiUserOption={upiUserOption}
          setUpiUserOption={setUpiUserOption}
          setUpiPayUserOption={setUpiPayUserOption}
          amountEntered={amountToPay}
          setRazorPayAmount={amountToPay}
          payType={payType}
          paymentTypeWallet={paymentTypeWallet}
          verifyBtn={verifyBtn}
          setVerifyBtn={setVerifyBtn}
          setPayType={setPayType}
          isUpiIdValid={isUpiIdValid}
          setIsUpiIdValid={setIsUpiIdValid}
          paymentFlag={paymentFlag}
          opean={opean}
          setOpean={setOpean}
          setMyWalletInBooking={setMyWalletInBooking}
          amountToAdd={amountToPay}
          setAmountToAdd={setAmountToPay}
          setPayFor={setPayFor}
          setPayOptionCheck={setPayOptionCheck}
          choosenPartial={choosenPartial}
          setChoosenPartial={setChoosenPartial}
          perWeekPrice={amountToPay}
          setUpiPay={setUpiPay}
          pageName="giftcard"
          finalPrice={finalPrice}
          useWalletBalancePayFlag={useWalletBalancePayFlagforDeposit}
          setUseWalletBalancePayFlag={setUseWalletBalancePayFlagforDeposit}
          multiplePaymentFlag={multiplePaymentFlag}
          setpaymentTriggerFlag={setpaymentTriggerFlag}
          handelCreateGiftCard={handelPayGiftCard}
        />
      )}

      {paymentSucess && (
        <DepositSucess
          open={paymentSucess}
          handelClose={handelClosePaymentSucess}
          heading={appMessages.giftCardHeading}
          subHeading={appMessages.giftCardSubHeading}
          image={giftCard}
        />
      )}
    </>
  );
}

const availedGiftCardStyles = {
  mainComponent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  myGiftCardTest: {
    fontSize: appTheme.fontSizexl,
    color: appColors.darkGray,
    fontWeight: "bold",
  },
  filterButton: {
    color: appColors.giftCardColor,
    fontWeight: "bold",
    fontSize: appTheme.fontSizeLarge,
  },
  giftCardList: {
    display: "flex",
    justifyContent: "space-evenly",
    alignContent: "flex-start",
    flexWrap: "wrap",
    height: { md: "60vh", xs: "76vh" },
    overflowY: "auto",
    marginBottom: "3rem",
    gap: ".5rem",
  },
};
