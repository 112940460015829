/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import { useDispatch } from "react-redux";
import { GetWalletDetails } from "../../Redux/actions/paymentMethodAction";
import orderSucess from "../../Assets/newMobileIcon/Booking/Can Order successful.svg";
import orderFailed from "../../Assets/newMobileIcon/Booking/Order Failed.svg";
import giftCardFail from "../../Assets/newMobileIcon/GiftCards/giftcard_pament_failure_final.png";
import giftCardSuccess from "../../Assets/newMobileIcon/GiftCards/giftcard_pament_success_final.png";
import { useHistory, useLocation } from "react-router-dom";
import { ResetComponentState } from "../../Redux/actions/appMetaDataAction";
import { AllOrderOtpPaymentDetails } from "../../Redux/actions/recentActivityAction";
import { ConsumerRatingState } from "../../Redux/actions/profileAction";
import CustomConsole from "../../coustomConsole";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
} from "@mui/material";
import { FcOk } from "react-icons/fc";
import appMessages from "../../Assets/AppMeta/appMessages";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RazorPayStatusDialog(props) {
  const { pathname, search } = useLocation();
  const pageName = new URLSearchParams(search).get("pageName");
  const pageTitle = pathname.split("/dashboard/");
  const dispatch = useDispatch();
  CustomConsole("---------RazorPayStatusDialog props here---------" + props);
  const history = useHistory();

  //function to handel close function
  const handleClose = () => {
    props.handleCloseRPDialog();
    dispatch(ResetComponentState());
    setTimeout(() => {
      dispatch(GetWalletDetails());
    }, 1000);
    props.handleClose();
    if (
      pageName !== "subscribe" &&
      pageName !== "subscription" &&
      pageTitle[1] !== "buygiftcard"
    ) {
      window.innerWidth > 600
        ? history.push("/dashboard/home")
        : history.push("/mobile");
    } else if (pageTitle[1] === "buygiftcard") {
      history.push("/dashboard/giftcardlist");
    } else if (pageTitle[1] === "giftcardlist") {
      window.innerWidth > 600
        ? history.push("/dashboard/home")
        : history.push("/mobile");
    }
    dispatch(AllOrderOtpPaymentDetails());
    if (!props.error) {
      dispatch(ConsumerRatingState(true));
    }
  };
  const bgcolor = appColors.backGroundGradient;

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <DialogContent sx={{ backgroundColor: appColors.secondaryCard }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {pageTitle[1] !== "buygiftcard" && pageTitle[1] !== "giftcards" ? (
              <img alt="" src={props.error ? orderFailed : orderSucess} />
            ) : (
              <img
                alt=""
                src={props.error ? giftCardFail : giftCardSuccess}
                style={{height:"12rem",objectFit:"cover" }}
              />
            )}
          </Box>{" "}
          {(pageTitle[1] === "buygiftcard" && !props.error) ||
          (pageTitle[1] === "giftcards" && !props.error) ? (
            <Typography
              sx={{
                fontSize: "16px",
                color: appColors.textColorNew,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {appMessages.giftCardSubHeading}
            </Typography>
          ) : (
            ""
          )}
          <Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            Payment Status: &nbsp;{props.error ? "Failed" : "Successfull"}&nbsp;
            {props.error ? <FcOk color="red" /> : <FcOk />}
          </Typography>
          {props.orderId ? (
            <Typography
              sx={{
                fontSize: "16px",
                color: appColors.darkGray,
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              Order ID: {props.orderId}
            </Typography>
          ) : (
            ""
          )}
          <Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            Amount: ₹{props.amount}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            Payment ID: {props.paymentId}
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              variant="contained"
              sx={{
                marginTop: "15px",
                height: "30px",
                backgroundColor: appColors.textColorNew,
                color: "white",
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
