/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 * @author Gowtham Pasath
 * @Date 06/12/2024
 */

import { useState, useEffect } from "react";
import AvailedGiftCards from "./availedGiftCards";
import { AppBar, Box, Button, Grid } from "@mui/material";
import ClaimGiftCard from "./claimGiftCard";
import appColors from "../../Assets/AppTheme/appColors";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
import { GetAvailableGiftCards } from "../../Redux/actions/giftCardAction";
import { useDispatch, useSelector } from "react-redux";

export default function GiftCardsList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const giftCardDetails = useSelector(
    (store) => store.GiftCardDetails.giftCardSetting
  );
  //function to push buy new gift cards
  const handelBuyNewGiftCards = () => {
    history.push("/dashboard/buygiftcard");
  };
  //useEffect to get gift card details
  useEffect(() => {
    dispatch(GetAvailableGiftCards());
  }, []);
  return (
    <Box sx={{ display: "flex", justifyContent: "center",}}>
      <Box sx={giftCardStyles.mainContent}>
        <Box sx={giftCardStyles.giftCardLists}>
          <AvailedGiftCards />
        </Box>
        {giftCardDetails?.isEnable && (
          <Box sx={giftCardStyles.buyNewGiftButton}>
            <Button
              variant="contained"
              size="medium"
              onClick={handelBuyNewGiftCards}
              sx={giftCardStyles.buttonStyles}
            >
              + Buy New Gift Cards
            </Button>
          </Box>
        )}
      </Box>
      <ToastMsgCotainer />
    </Box>
  );
}

const giftCardStyles = {
  mainContent: {
    marginTop: { xs: "3rem", sm: "4rem" },
    boxShadow:2,
    borderRadius: "1rem",
    width: { md: "60vw" },
    height: { xs: "100vh", md: "80vh" },
    background: appColors.cardColor,
    position: "relative",
    textAlign: "center",
    overflow: "hidden", // Prevent overflow of the entire container
  },
  spacingComponent: {
    borderBottom: "2px dashed gray",
    width: "100%",
    marginTop: "1rem",
  },
  giftCardLists: {
    padding: "1rem",
  },
  buyNewGiftButton: {
    background: { xs: appColors.checkOutCard, sm: "transparent" },
    bottom: 0,
    left: { xs: 0, sm: "45%" },
    // transform: "translate(-50%, -50%)",
    // height: "3rem",
    width: { sm: "20rem", xs: "100%" },
    position: "fixed",
    // borderRadius: "1rem",
    textAlign: "center",
    padding: ".5rem",
  },
  buttonStyles: {
    background: appColors.giftCardColor,
    borderRadius: "1rem",
    width: "100%",
  },
};
