import React from 'react';
import './style.css';

const CustomDivider = ({dividerText}) => {
  return (
    <div class="divider-container">
    <div class="divider-line"></div>
    <span class="divider-text">{dividerText}</span>
    <div class="divider-line"></div>
  </div>
  );
};

export default CustomDivider;