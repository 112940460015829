/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";

export const bookingCards = {
  saleTypeCard: {
    display: "flex",
    justifyContent:{xs:"space-between",sm:"flex-start"} ,
    gap: 2,
    margin: ".5rem 0",
  },
  bookingMainBox: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: "flex-start",
    width: "100%",
    flexWrap: "wrap",
    gap: { sm: 1 },
    marginBottom: "2rem",
  },
  mainCardStyles: {
    boxShadow: 5,
    elevation: 5,
    margin: ".2rem",
    padding: "0 0.5rem 0 0",
    width: { xs: "calc(100% - 0.6rem)", sm: "23rem" },
    height: "100%",
    padding: ".3rem .5rem 0 0",

    position: "relative",
    bgcolor: appColors.cardColor,
    borderRadius: ".7rem",
  },
  imageContainer: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: ".1rem",
    height: "5rem",
    width: "100%", // Ensures the box takes the full available width
    overflow: "hidden", // Ensures the image is not displayed outside the box
  },
  productInfo: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  labelStyles: {
    position: "absolute",
    top: 0,
    right: 0,
    bgcolor: appColors.textColorNew,
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    boxShadow: 1,
  },
  addButtonStyles:{
    bgcolor: appColors.textColorNew,
    height: "1.8rem",
    color: "#fff",
    marginTop: ".5rem",
    fontSize: ".8rem",
  }
};

export const cardStyle = {
  orderOverviewCardText: {
    fontSize: "14px",
    fontWeight: "600",
    color: appColors.darkGray,
  },
  addressParentCard: {
    color: "white",
  },
  addressHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "3px",
  },
  addressHolderTypo: {
    fontSize: appTheme.fontSizeSmall,
    wordBreak: "break-word",
    // marginTop: "5px",
  },
  addAdressParentCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    border: "1px solid #7e7e7e",
    marginLeft: "1rem",
    "&: hover": {
      backgroundColor: "#152058",
      color: "white",
    },
  },
  addAddressChildBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "30px",
  },
  deliverydateCard: { marginTop: "10px", marginBottom: "10px" },
  deliverydateCardChildBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "3px",
  },
  deliveryCardTypo: {
    fontSize: "13px",
    marginTop: "5px",
    marginLeft: "5px",
  },
  overViewCardHeading: {
    fontSize: "15px",
    fontWeight: "600",
    textAlign: "center",
    color: appColors.secondaryText,
  },
  ovcObjMap_1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "7px",
  },
  ovcHr: {
    border: "1px solid grey",
    borderWidth: "0.5px",
  },
  ovcObjMap_2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  taxParentBox: {
    backgroundColor: appColors.textColorNew,
    color: "white",
    borderRadius: "3px",
    padding: "5px",
  },
  taxParentBox1: {
    backgroundColor: appColors.textColorNew,
    color: "white",
    borderRadius: "3px",
    padding: "5px",
  },
  taxChildBox: {
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginBottom: "3px",
  },
  flexSpaceBtw: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalPriceBox: {
    height: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalPriceChildBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
    cursor: "pointer",
  },
};

//Footer Styles
export const FooterStyles = {
  footerMain: {
    backgroundColor: appColors.newBookingLightBlue,
    top: "auto",
    bottom: 0,
    height: "auto",
    width: "100%",
  },
  footerMainWallet: {
    backgroundColor: appColors.newBookingLightBlue,
    top: "auto",
    bottom: 0,
    height: "5rem",
    width: "100%",
  },
  footerIcons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    marginTop: ".3rem",
  },
  waterCan: { height: "2.5rem", width: "4.5rem", marginTop: "2rem" },
  footerText: { marginTop: ".4rem", fontSize: ".7rem" },
  homeText: { marginBottom: "2rem", fontSize: ".8rem" },
};
